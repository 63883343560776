'use client';
import { FC, useEffect } from 'react';
import { useBridgeContext } from '@/BridgeProvider';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { useBridgeRecipient } from '@/hooks/bridge/useBridgeRecipient';
import { useToggle } from 'react-use';
import { AmountInputColorTheme } from '@/constants';

interface Props {
  className?: string;
  type?: 'orange' | 'red';
}

export const RecepientSection: FC<Props> = ({ className = '', type = 'orange' }) => {
  const [showInput, toggleShoeInput] = useToggle(false);
  const { state, dispatch } = useBridgeContext();

  const { isRecipientRequired, isRecipientInputValid } = useBridgeRecipient();

  const isMissingRecipient = isRecipientRequired && !state.recipientValue;

  useEffect(() => {
    if (isRecipientRequired) {
      toggleShoeInput(true);
    }
  }, [isRecipientRequired, toggleShoeInput]);

  return (
    <div className={twMerge('w-full text-xs text-neutral-500 mt-2', className)}>
      <div className="flex">
        <input
          id="confirmWithdraw"
          type="checkbox"
          value=""
          onChange={toggleShoeInput}
          checked={showInput}
          disabled={isRecipientRequired}
          className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 rounded
                focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor="confirmWithdraw" className="ml-2 text-xs">
          Recipient address {isRecipientRequired ? '' : '(optional)'}
        </label>
      </div>
      {showInput && (
        <div className="flex flex-col gap-y-2 mt-4">
          <input
            type="text"
            className={twMerge(
              'text-neutral-100 bg-neutral-900 border-neutral-600 rounded-lg outline-none py-3 px-4 border w-full text-sm sm:text-base min-h-[48px]',
              clsx({
                [AmountInputColorTheme[type].border]: isMissingRecipient || !isRecipientInputValid,
                [AmountInputColorTheme[type].text]: isMissingRecipient || !isRecipientInputValid,
                [AmountInputColorTheme[type].background]: isMissingRecipient || !isRecipientInputValid,
              })
            )}
            placeholder="Enter recipient wallet address"
            value={state.recipientValue.toString()}
            onChange={(e) => {
              const val = e.target.value;

              dispatch({
                type: 'SET_RECIPIENT_VALUE',
                payload: val,
              });
            }}
          />
          {isMissingRecipient && (
            <div className="flex items-center mt-2">
              <Image src={'/assets/icons/warning.svg'} alt="Insufficient funds available" width={16} height={16} />
              <div className="text-orange-500 text-xs ml-2">Required</div>
            </div>
          )}
          {!isRecipientInputValid && (
            <div className="flex items-center">
              <div className="text-[#F12150] text-xs uppercase">Invalid wallet address</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
