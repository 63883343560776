'use client';

import clsx from 'clsx';
import { useBridgeContext } from '@/BridgeProvider';

export const Tabs = () => {
  const { state, dispatch } = useBridgeContext();

  function renderTab(label: string, view: typeof state.view) {
    return (
      <div
        className={clsx(
          state.view === view
            ? 'bg-primary-500/[.08] rounded text-primary-500 border border-primary-500'
            : 'cursor-pointer text-neutral-100',
          'font-medium w-full text-xs uppercase flex justify-center items-center p-2'
        )}
        onClick={() => state.view !== view && dispatch({ type: 'SET_VIEW', payload: view })}
      >
        {label}
      </div>
    );
  }

  return (
    <div className="flex border rounded-lg p-1 border-[#262626]">
      {renderTab('Deposit', 'deposit')}
      {renderTab('Withdraw', 'withdraw')}
    </div>
  );
};
