import { THIRD_PARTY_BRIDGE_ONLY_TOKENS, THIRD_PARTY_BRIDGE_ONLY_NETWORK_IDS } from '@/constants/tokens';
import { useBridgeContext } from '@/BridgeProvider';
import { useMemo } from 'react';

export function useThirdPartyOnlyBridge(source: 'from' | 'to') {
  const { state } = useBridgeContext();
  const networkId = state[source].network;

  const isThirdPartyOnlyToken = useMemo(() => {
    return THIRD_PARTY_BRIDGE_ONLY_TOKENS.includes(state.selectedCurrency);
  }, [state.selectedCurrency]);

  const isThirdPartyOnlyNetwork = useMemo(() => {
    return THIRD_PARTY_BRIDGE_ONLY_NETWORK_IDS.includes(networkId);
  }, [networkId]);

  return { isThirdPartyOnlyToken, isThirdPartyOnlyNetwork };
}
