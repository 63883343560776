import { ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { PopperContentProps } from '@radix-ui/react-popper';

export const Tooltip = ({
  trigger,
  children,
  align = 'start',
  side = 'top',
  tooltipTitle,
}: {
  trigger: ReactNode;
  children: ReactNode;
  align?: PopperContentProps['align'];
  side?: PopperContentProps['side'];
  tooltipTitle?: string;
}) => {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Content
          align={align}
          side={side}
          alignOffset={16}
          className="rounded-2xl border border-neutral-800 bg-neutral-950 z-10
                    text-xs max-w-[360px] p-4 text-neutral-600 font-normal normal-case
                    animate__animated animate__fadeIn animate__delay-0.3s animate__faster"
        >
          {tooltipTitle ? <h6 className="font-bold text-lg text-white uppercase mb-2">{tooltipTitle}</h6> : null}
          {children}
        </RadixTooltip.Content>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
