type InviteData = {
  invitedBy: string;
  redirectId: string;
};

export const setInviteDataToLocalStorage = (inviteData: InviteData) => {
  localStorage.setItem('invitedBy', inviteData.invitedBy);
  localStorage.setItem('redirectId', inviteData.redirectId);
  localStorage.setItem('enteredValidCode', 'true');
};

export const clearAirdropDataFromLocalStorage = () => {
  localStorage.removeItem('invitedBy');
  localStorage.removeItem('redirectId');
  localStorage.removeItem('enteredValidCode');
  localStorage.removeItem('userData');
};

export const getInviteDataFromLocalStorage = () => ({
  invitedBy: localStorage.getItem('invitedBy'),
  redirectId: localStorage.getItem('redirectId'),
});
