import { useAsync } from 'react-use';
import { useBridgeContext } from '@/BridgeProvider';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { providers } from '@/utils/getTokenBalances';

function isValidEthereumAddress(address: string): boolean {
  return ethers.utils.isAddress(address);
}

export function useBridgeRecipient() {
  const { state } = useBridgeContext();
  const { address } = useAccount();

  const { value: isRecipientRequired, loading } = useAsync(async () => {
    if (!address) {
      return false;
    }

    // check if active wallet is smart contract or not
    const codel1 = await providers.l1.getCode(address);
    const codel2 = await providers.l2.getCode(address);

    return codel1 !== '0x' || codel2 !== '0x'; // for regular wallet we get 0x but for smart contract (safe) we get actual code
  }, [address]);

  const isRecipientInputValid = !state.recipientValue || isValidEthereumAddress(state.recipientValue);
  const isMissingRecipient = isRecipientRequired && !state.recipientValue;

  return {
    isMissingRecipient,
    isRecipientInputValid,
    isRecipientRequired,
    isLoading: loading,
  };
}
