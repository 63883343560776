import { useAsyncFn } from 'react-use';
import { parseBigNumberToFloat } from '@/utils/format';
import { WithdrawalData } from '@/components/Withdrawals/types';
import { useEthersSigner } from '@/utils/ethers';
import { getL1SignerCrossChainMessenger } from '@/components/Withdrawals/helpers';
import { TokenBridgeMessage } from '@eth-optimism/sdk';
import { NETWORK_TOKENS } from '@/constants/tokens';
import localConfig from '@/config';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/constants/queryKeys';
import { getTokensPrice } from '@/services/coinGecko';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import axios from 'axios';
import { ExplorerTransaction } from '@/types/expoler';
import { getWstEthCrossChainMessenger } from '@/utils/getCrossChainMessenger';
import { Token } from '@/types/token';

const requiredChainId = parseInt(process.env.NEXT_PUBLIC_L1_CHAIN_ID!);

const MessageStatusTableInfo = {
  0: 'UNCONFIRMED L1 TO L2 MESSAGE',
  1: 'FAILED L1 TO L2 MESSAGE',
  2: 'Submitting to L1',
  3: 'Submitted, ready to confirm',
  4: 'Confirmed, waiting in challenge period (1 week)',
  5: 'Ready to finalise',
  6: 'Finalised/Completed',
};

const isWstEthWithdrawalMessage = (message: TokenBridgeMessage) => {
  return (
    message.l1Token === '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0' &&
    message.l2Token === '0x98f96A4B34D03a2E6f225B28b8f8Cb1279562d81'
  );
};
async function fetcher(message: TokenBridgeMessage): Promise<WithdrawalData | undefined> {
  const l1Messenger = await getL1SignerCrossChainMessenger();
  const wstEthMessanger = await getWstEthCrossChainMessenger();

  const l1Token = NETWORK_TOKENS[parseInt(localConfig.l1ChainId)].find(
    (item) => item.contractAddress.toLowerCase() === message.l1Token.toLowerCase()
  );

  try {
    const { data } = await axios.get<ExplorerTransaction>(
      `${localConfig.explorerUrl}/api/v2/transactions/${message.transactionHash}`
    );

    const status = isWstEthWithdrawalMessage(message)
      ? await wstEthMessanger.getMessageStatus(message)
      : await l1Messenger.getMessageStatus(message);

    const prices = await getTokensPrice();
    const amount = parseBigNumberToFloat(message.amount, l1Token?.decimals);

    return {
      message,
      amount,
      status: MessageStatusTableInfo[status],
      statusCode: status,
      transactionHash: message.transactionHash,
      token: l1Token,
      usdAmount:
        l1Token && prices[l1Token.symbol?.toLowerCase()]
          ? (prices[l1Token.symbol?.toLowerCase()] * amount).toFixed(2)
          : 0,
      transaction: data,
    };
  } catch (e) {
    console.error(e);
    return {
      message,
      amount: parseBigNumberToFloat(message.amount, l1Token?.decimals),
      status: 'n/a',
      statusCode: null,
      transactionHash: message.transactionHash,
      token: l1Token,
      usdAmount: 0,
      transaction: undefined,
    };
  }
}

function useDecodedMessage(message: TokenBridgeMessage, options = {}) {
  return useQuery<WithdrawalData | undefined>({
    queryKey: [QueryKeys.BRIDGE_MESSAGE, message],
    queryFn: async () => {
      return fetcher(message);
    },
    ...options,
  });
}

async function getCrossChainMessanger(token: Token | undefined) {
  return token?.symbol === 'wstETH' ? await getWstEthCrossChainMessenger() : await getL1SignerCrossChainMessenger();
}

export function useWithdrawalDetails(message: TokenBridgeMessage): {
  isLoading: boolean;
  data: WithdrawalData | undefined;
  submitting: boolean;
  handleProve: () => Promise<TransactionResponse | Error | undefined>;
  handleFinalize: () => Promise<TransactionResponse | Error | undefined>;
} {
  const queryClient = useQueryClient();
  const getL1signer = useEthersSigner({
    chainId: requiredChainId,
  });

  const { data: value, isLoading } = useDecodedMessage(message);

  const [{ loading: submittingProve }, handleProve] = useAsyncFn(async () => {
    if (!value) {
      return;
    }

    const l1Messenger = await getCrossChainMessanger(value.token);

    const l1signer = await getL1signer();

    const res = await l1Messenger.proveMessage(value.message, {
      signer: l1signer,
    });

    return new Promise<TransactionResponse | Error>((resolve) => {
      setTimeout(async () => {
        queryClient.removeQueries({
          queryKey: [QueryKeys.BRIDGE_MESSAGE, value.message],
        });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.BRIDGE_MESSAGE] });

        resolve(res);
      }, 7000);
    });
  }, [value, getL1signer, queryClient]);

  const [{ loading: submittingFinalize }, handleFinalize] = useAsyncFn(async () => {
    if (!value) {
      return;
    }

    const l1Messenger = await getCrossChainMessanger(value.token);

    const l1signer = await getL1signer();

    const res = await l1Messenger.finalizeMessage(value.message, {
      signer: l1signer,
    });

    return new Promise<TransactionResponse | Error>((resolve) => {
      setTimeout(async () => {
        queryClient.removeQueries({
          queryKey: [QueryKeys.BRIDGE_MESSAGE, value.message],
        });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.BRIDGE_MESSAGE] });
        queryClient.removeQueries({ queryKey: [QueryKeys.TOKEN_BALANCES] });

        resolve(res);
      }, 7000);
    });
  }, [value, getL1signer, queryClient]);

  return {
    data: value,
    isLoading,
    submitting: submittingFinalize || submittingProve,
    handleProve,
    handleFinalize,
  };
}
