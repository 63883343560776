'use client';

import { ArrowIcon, CheckIcon, ExternalArrowIcon } from '@/components';
import { WithdrawalData } from '@/components/Withdrawals/types';
import localConfig from '@/config';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { toOptionalFixed } from '@/utils/format';
import { getNetworkIcon } from '@/utils/wallet';
import clsx from 'clsx';
import { format } from 'date-fns';
import Image from 'next/image';

export const HistoryWithdrawalItem = ({ data, compact }: { data: WithdrawalData; compact?: boolean }) => {
  const { localize } = useLocalizedContentfulStrings();

  return (
    <div className="flex items-center w-full mt-4">
      <div className="w-1/3 flex flex-col">
        <div className="flex items-center gap-x-1">
          <div className="flex items-center justify-center w-4 h-4 rounded-full bg-neutral-800">
            <CheckIcon className="text-neutral-100" />
          </div>
          <p className="text-neutral-100 uppercase font-medium">{`${localize('bridged')} ${data.token?.symbol}`}</p>
        </div>
        {data.transaction?.timestamp && (
          <p className={compact ? 'text-[10px]' : 'text-sm'}>
            {format(new Date(data.transaction?.timestamp), 'dd MMM yyyy, hh:mm a')}
          </p>
        )}
      </div>
      <div className="flex items-center justify-center w-1/3">
        <Image
          className="rounded-full"
          src={getNetworkIcon(+localConfig.l2ChainId)}
          alt="l2ChainId"
          width={24}
          height={24}
        />
        <ArrowIcon size={24} className="text-neutral-100" />
        <Image
          className="rounded-full"
          src={getNetworkIcon(+localConfig.l1ChainId)}
          alt="l1ChainId"
          width={24}
          height={24}
        />
      </div>
      <div className="w-1/3 flex justify-end gap-x-1">
        <div className="text-right">
          <p className="text-neutral-100 uppercase font-medium">
            {data ? toOptionalFixed(data.amount, 6) : ''} {data?.token?.symbol}
          </p>
          <p className={compact ? 'text-[10px]' : 'text-sm'}>${data?.usdAmount}</p>
        </div>
        <div className={clsx(compact ? 'w-4 h-4' : 'w-6 h-6')}>
          {data.transaction?.hash && (
            <a
              href={`${localConfig.explorerUrl}/tx/${data.transaction.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalArrowIcon size={compact ? 16 : 24} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
