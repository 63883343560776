import { useAccount, useBalance } from 'wagmi';
import { useBridgeContext } from '@/BridgeProvider';
import localConfig from '@/config';
import { useCallback, useEffect } from 'react';
import { parseBigNumberToFloat } from '@/utils/format';
import { BigNumber } from 'ethers';
import fetchTokenBalances from '@/utils/getTokenBalances';

export function useUpdateTokenBalances() {
  const { address, chain } = useAccount();

  const { dispatch } = useBridgeContext();

  const { data: l1_ETH } = useBalance({
    address,
    chainId: parseInt(localConfig.l1ChainId),
    query: {
      enabled: !!address,
    },
  });

  const { data: l2_ETH } = useBalance({
    address,
    chainId: parseInt(localConfig.l2ChainId),
    query: {
      enabled: !!address,
    },
  });

  const getTokenBalances = useCallback(() => {
    if (!address || !l1_ETH || !l2_ETH || !chain) {
      return;
    }

    // use balance return bigInt
    const eth_l1 = parseBigNumberToFloat(BigNumber.from(l1_ETH.value));

    const eth_l2 = parseBigNumberToFloat(BigNumber.from(l2_ETH.value));

    return fetchTokenBalances(address).then((data) => {
      const l1Balances: Record<string, number | string> = {};
      const l2Balances: Record<string, number | string> = {};

      data.l1Balances.forEach((item) => {
        const key = Object.keys(item)[0];

        l1Balances[key] = item[key]!;
      });

      l1Balances['ETH'] = parseFloat(eth_l1.toFixed(4));

      data.l2Balances.forEach((item) => {
        const key = Object.keys(item)[0];

        l2Balances[key] = item[key]!;
      });
      l2Balances['ETH'] = parseFloat(eth_l2.toFixed(4));

      return { l1: l1Balances, l2: l2Balances };
    });
  }, [address, chain, l1_ETH, l2_ETH]);

  const updateTokenBalances = useCallback(() => {
    getTokenBalances()?.then((data) => {
      dispatch({
        type: 'SET_BALANCES',
        payload: data,
      });
    });
  }, [dispatch, getTokenBalances]);

  useEffect(() => {
    updateTokenBalances();
  }, [updateTokenBalances]);

  useEffect(() => {
    if (!chain) {
      dispatch({
        type: 'SET_BALANCES',
        payload: { l1: { ETH: 0 }, l2: { ETH: 0 } },
      });

      dispatch({
        type: 'SET_VALUE',
        payload: 0,
      });
    }
  }, [chain, dispatch]);

  return {
    getTokenBalances,
    updateTokenBalances,
  };
}
