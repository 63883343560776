'use client';

import { useLocationContext } from '@/app/context/LocationContext';
import { useBridgeContext } from '@/BridgeProvider';
import { Button } from '@/components';
import { ConfirmDialog } from '@/components/ConfirmDialog/ConfirmDialog';
import { InlineLoader } from '@/components/InlineLoader';
import { ReviewWithdrawal } from '@/components/ReviewWithdrawal';
import localConfig from '@/config';
import { useBridgeRecipient } from '@/hooks/bridge/useBridgeRecipient';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { useTokenApprove } from '@/hooks/bridge/useTokenApprove';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { default as Image } from 'next/image';
import { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { useAccount } from 'wagmi';

interface Props {
  onSubmitHandler: () => Promise<void | null | `0x${string}` | unknown>;
  isLoading: boolean;
  source: 'from' | 'to';
}

export const SubmitBridgeButton: FC<Props> = ({ onSubmitHandler, isLoading, source }) => {
  const [showSameAddressWarning, toggleShowSameAddressWarning] = useToggle(false);

  const { address, chain } = useAccount();
  const { isConnected } = useAccount();
  const { state } = useBridgeContext();
  const { openConnectModal } = useConnectModal();
  const layer = state.view === 'deposit' ? 'L1_balances' : 'L2_balances';
  const isBalanceExceeded = +state[layer][state.selectedCurrency] < +state.inputValue;
  const { isThirdPartyOnlyToken } = useThirdPartyOnlyBridge(source);
  const { allowance, approve, approving, waiting, setWaiting, approveValue } = useTokenApprove(state.from.token);

  const { isReadOnly } = useLocationContext();

  const { isRecipientRequired, isRecipientInputValid } = useBridgeRecipient();
  const approveSuccessMessage =
    state.view === 'deposit' ? 'You can now Bridge to mode' : 'You can now Withdraw from mode';

  useEffect(() => {
    if (waiting && allowance !== undefined && allowance !== null && +allowance >= +state.inputValue) {
      setWaiting(false);

      if (approveValue) {
        toast.success(
          <div className="uppercase">
            <p>Approval confirmed successfully</p>
            <p>{approveSuccessMessage}</p>
          </div>
        );
      }
    }
  }, [allowance, setWaiting, state.inputValue, waiting, approveValue, approveSuccessMessage]);

  function getSubmitButtonLabel() {
    if (isLoading) {
      return (
        <>
          <Image
            src="/assets/icons/loading-icon-grey.svg"
            alt="Close"
            width={24}
            height={24}
            className="animate-spin"
          />
          <InlineLoader label="Processing..." className="text-neutral-950" />
        </>
      );
    }

    if (state.view === 'deposit') {
      return 'Bridge to MODE';
    }

    return 'Bridge to ETHEREUM';
  }

  const isNetworkMismatch =
    (state.view === 'deposit' && chain?.id !== parseInt(localConfig.l1ChainId)) ||
    (state.view === 'withdraw' && chain?.id !== parseInt(localConfig.l2ChainId));

  const isMissingRecipient = isRecipientRequired && !state.recipientValue;

  const handleSubmit = useCallback(async () => {
    if (isRecipientRequired && address?.toLowerCase() === state.recipientValue?.toLowerCase()) {
      toggleShowSameAddressWarning(true);
    } else {
      await onSubmitHandler();
    }
  }, [address, isRecipientRequired, onSubmitHandler, state.recipientValue, toggleShowSameAddressWarning]);

  if (isReadOnly || isThirdPartyOnlyToken) return null;

  if (!isConnected) {
    return (
      <Button onClick={openConnectModal} className="mt-6 w-full flex items-center justify-center">
        Connect Wallet
      </Button>
    );
  }

  if (isNetworkMismatch) {
    return (
      <Button onClick={onSubmitHandler} className="mt-6 w-full flex items-center justify-center bg-[#DFE0E1]">
        Switch network
      </Button>
    );
  }

  if (state.view === 'withdraw' && isConnected) {
    return <ReviewWithdrawal onSubmit={onSubmitHandler} isLoading={isLoading} />;
  }

  if (allowance !== undefined && allowance !== null && +allowance < +state.inputValue) {
    const approveNotRejected = waiting && !!approveValue;
    return (
      <Button
        onClick={async () => {
          setWaiting(true);
          await approve();
        }}
        className="mt-6 w-full flex items-center justify-center bg-[#DFE0E1]"
        disabled={approving || approveNotRejected}
      >
        {approving || approveNotRejected ? (
          <Image
            src="/assets/icons/loading-icon-grey.svg"
            alt="Close"
            width={24}
            height={24}
            className="animate-spin"
          />
        ) : null}
        {approving || approveNotRejected ? 'Approving Transaction' : 'Approve'}
      </Button>
    );
  }

  const isAllowedToBridge =
    state.selectedCurrency === 'ETH' || (allowance != null && Number(allowance) >= Number(state.inputValue));

  return (
    <>
      <Button
        onClick={handleSubmit}
        className="mt-6 w-full flex items-center justify-center bg-[#DFE0E1]"
        disabled={
          !(Number(state.inputValue) > 0) ||
          isBalanceExceeded ||
          !isAllowedToBridge ||
          isMissingRecipient ||
          !isRecipientInputValid ||
          isLoading
        }
      >
        {getSubmitButtonLabel()}
      </Button>
      <ConfirmDialog
        open={showSameAddressWarning}
        onClose={() => toggleShowSameAddressWarning(false)}
        onSubmit={() => {
          toggleShowSameAddressWarning(false);
          onSubmitHandler();
        }}
        title="Confirm"
        text="Ensure that sending to multisig that is as same as requester multisig address means that multisig must exist on Mode or vice versa"
      />
    </>
  );
};
