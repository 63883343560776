import { useAsyncFn, useToggle } from 'react-use';
import erc20_json from '@/constants/abis/ERC20.json';
import { useAccount, usePublicClient, useReadContract, useWaitForTransactionReceipt, useWalletClient } from 'wagmi';
import { Token } from '@/types/token';
import { NATIVE_TOKEN_ADDRESS } from '@/constants/tokens';
import localConfig from '@/config';
import { utils } from 'ethers';
import { useBridgeContext } from '@/BridgeProvider';
import { formatUnits } from 'ethers/lib/utils';
import { useEffect } from 'react';

const defaultGasLimit = 200000;
const tokenDepositGasLimit = BigInt(parseInt(localConfig.tokenDepositGasLimit) || defaultGasLimit);

export function useTokenApprove(token: Token) {
  const { address } = useAccount();
  const { state } = useBridgeContext();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const [waiting, setWaiting] = useToggle(false);

  const { data: allowanceResult, refetch } = useReadContract({
    address: token.contractAddress as `0x${string}`,
    abi: erc20_json.abi,
    functionName: 'allowance',
    args: [address, token.bridge ? token.bridge : localConfig.l1BridgeAddress],
    query: {
      enabled: !!state.inputValue && address && token.contractAddress !== NATIVE_TOKEN_ADDRESS,
    },
  });

  const [{ loading: loadingApprove, value: approveValue }, approve] = useAsyncFn(async () => {
    if (!publicClient || !walletClient || !address || token.contractAddress === NATIVE_TOKEN_ADDRESS) {
      return;
    }

    const amount = utils.parseUnits(state.inputValue.toString(), token.decimals);

    const bridgeAddress = token.bridge ? token.bridge : localConfig.l1BridgeAddress.toLowerCase();

    return walletClient.writeContract({
      address: token.contractAddress as `0x${string}`,
      abi: erc20_json.abi,
      functionName: 'approve',
      args: [bridgeAddress, amount],
      account: address,
      gas: tokenDepositGasLimit,
    });
  }, [state, address, publicClient, token, walletClient]);

  const {
    data,
    isLoading: isApproving,
    status,
  } = useWaitForTransactionReceipt({
    hash: approveValue,
    query: {
      enabled: !!state.inputValue && !!approveValue && address && token.contractAddress !== NATIVE_TOKEN_ADDRESS,
    },
  });

  useEffect(() => {
    if (data && status === 'success') {
      refetch();
    }
  }, [data, refetch, status]);

  return {
    waiting,
    allowance:
      allowanceResult !== null && allowanceResult !== undefined
        ? formatUnits(allowanceResult as bigint, token.decimals)
        : null,
    setWaiting,
    approve,
    approving: loadingApprove || isApproving,
    getAllowance: refetch,
    approveValue,
  };
}
