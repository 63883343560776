'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';

export type TabType = {
  title: ReactNode;
  value: string;
};

const TabsFunction = ({ className, children }: { className?: string; children: ReactNode }) => {
  return <div className={clsx('rounded-lg border border-neutral-900 p-1 px-2 flex w-fit', className)}>{children}</div>;
};

export interface TabItemFunctionProps {
  tab: TabType;
  active: boolean;
  className?: string;
  variant?: 'primary' | 'secondary';
  onClick: (value: string) => void;
}

const TabItemFunction = ({ tab, active, className, variant = 'primary', onClick }: TabItemFunctionProps) => {
  const rootClassName = 'rounded uppercase py-2 px-5 shrink-0 text-xs font-medium h-full text-neutral-100';
  const secondaryClassName = 'border border-transparent';
  const activeClassName =
    variant === 'primary'
      ? 'bg-primary-500 text-neutral-black'
      : 'border border-primary-500 text-neutral-100 bg-fillAccent-80';

  return (
    <div
      role="button"
      onClick={() => onClick(tab.value)}
      className={clsx(
        rootClassName,
        { [secondaryClassName]: !active && variant === 'secondary', [activeClassName]: active },
        className
      )}
    >
      {tab.title}
    </div>
  );
};

export const Tabs = Object.assign(TabsFunction, { Item: TabItemFunction });
