import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  label?: string;
  className?: string;
}

export const InlineLoader: FC<Props> = ({ className, label = 'Loading...' }) => {
  return <div className={twMerge('text-neutral-100 font-medium text-base animate-pulse', className)}>{label}</div>;
};
