import { ReactNode } from 'react';
import clsx from 'clsx';
import { Chakra_Petch } from 'next/font/google';
import { twMerge } from 'tailwind-merge';

const CHAKRA = Chakra_Petch({
  subsets: ['latin'],
  display: 'swap',
  weight: ['700'],
});

export const SectionContainer = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={clsx(className, 'rounded-2xl bg-neutral-950 p-4 flex flex-col')}>{children}</div>;
};

export const HeaderSectionBox = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={clsx(className, 'rounded bg-neutral-900 p-2 flex flex-col')}>{children}</div>;
};

export const PageTitle = ({ children }: { children: string }) => {
  return (
    <h1 className={`${CHAKRA.className} text-neutral-100 uppercase text-[28px] md:text-[48px] font-bold mb-4 md:mb-8`}>
      {children}
    </h1>
  );
};

export const SectionTitle = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <h4 className={twMerge(CHAKRA.className, 'text-[28px] font-medium text-neutral-100 uppercase', className)}>
      {children}
    </h4>
  );
};

export const SectionDesc = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <h6 className={clsx(className, 'text-xs font-medium uppercase')}>{children}</h6>;
};

export const Paragraph = ({ children }: { children: ReactNode }) => {
  return <p className="block text-[16px] text-neutral-500 mb-4">{children}</p>;
};
