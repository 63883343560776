'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import merge from 'lodash.merge';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useOnClickOutside } from 'usehooks-ts';

type Option<T> = {
  icon?: string;
  label: string;
  value: T;
};

interface DropdownProps<T> {
  options: Option<T>[];
  onToggle?: (newState: boolean) => void;
  label: string;
  position?: 'left' | 'right';
  disabled?: boolean;
  selectedValue: Option<T> | undefined;
  onSelect: (val: T) => void;
  className?: string;
}

export const TokenSelectorDropdown = <T,>({
  options,
  onToggle,
  label,
  position,
  disabled,
  selectedValue,
  onSelect,
  className,
}: DropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  function openCloseDropdown() {
    if (open) {
      setOpen(!open);
    }
  }

  const popperRef = useRef(popperElement);
  const referenceRef = useRef(referenceElement);

  useEffect(() => {
    popperRef.current = popperElement;
    referenceRef.current = referenceElement;
  }, [popperElement, referenceElement]);

  useOnClickOutside([popperRef, referenceRef], openCloseDropdown);

  const opts = merge(
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
    options
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, opts);

  const handleOptionClick = (option: Option<T>) => {
    onSelect(option.value);
    setOpen(false);
  };

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open, onToggle]);

  return (
    <div
      className={clsx(className, 'inline-block w-full relative text-neutral-500')}
      style={{
        position: 'relative',
        zIndex: open ? 21 : 20,
      }}
    >
      <div>
        <button
          ref={setReferenceElement}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          type="button"
          className={clsx(
            'inline-flex items-center w-full px-3 sm:px-4 py-2 font-medium border border-neutral-600  bg-neutral-900',
            {
              'rounded-tl-lg rounded-bl-lg': position === 'left',
              'rounded-tr-lg rounded-br-lg': position === 'right',
              'border-s-0': position === 'right',
            }
          )}
          id="options-menu"
          aria-haspopup="listbox"
          style={{
            minWidth: '120px',
          }}
        >
          <div className="flex flex-col items-start gap-1 w-full">
            <div className="text-xs whitespace-nowrap overflow-hidden overflow-ellipsis mb-1">{label}</div>
            <div className="flex w-full">
              {selectedValue?.icon && <Image src={selectedValue.icon} alt="svg" width={24} height={24} />}
              <span className="text-neutral-100 mx-2 font-normal whitespace-nowrap overflow-hidden overflow-ellipsis">
                {selectedValue?.label}
              </span>
              {!disabled && <Image src={'/down_arrow.svg'} alt="svg" width={24} height={24} className="ml-auto" />}
            </div>
          </div>
        </button>
      </div>

      <AnimatePresence>
        {open && (
          <div className="w-full" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            <motion.ul
              initial={{ opacity: 0, transform: 'translateY(20px)' }}
              animate={{ opacity: 1, transform: 'translateY(0px)' }}
              exit={{ opacity: 0 }}
              role="listbox"
              aria-labelledby="options-menu"
              aria-activedescendant="selected-option"
              className="py-1 w-full sm:w-auto rounded-lg border border-neutral-600 bg-neutral-900"
            >
              {options.map((option, ix) => (
                <li
                  key={`${option.label}-${ix}`}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer select-none relative px-4 py-2 flex items-center"
                >
                  <div className="flex items-center space-x-2">
                    {option.icon && <Image src={option.icon} alt="svg" width={24} height={24} className="ml-auto" />}
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {' '}
                      {option.label}{' '}
                    </span>
                  </div>
                </li>
              ))}
            </motion.ul>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
