'use client';

import { useLocationContext } from '@/app/context/LocationContext';
import { Button, ChevronIcon, DropdownButton, PocketbookIcon, WalletDropdown } from '@/components';
import { useConnectionStatus } from '@/hooks/wallet/useConnectionStatus';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import clsx from 'clsx';
import Image from 'next/image';

export function CustomRainbowButton() {
  useConnectionStatus();
  const { isReadOnly } = useLocationContext();

  return (
    <ConnectButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
        return (
          <div
            className={clsx(
              !ready && 'pointer-events-none opacity-0',
              'flex items-center text-xs font-medium text-neutral-100'
            )}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button disabled={isReadOnly} onClick={openConnectModal}>
                    Connect wallet
                    <PocketbookIcon className="ml-2 text-neutral-black" />
                  </Button>
                );
              }
              if (!chain) {
                return <Button onClick={openChainModal}>Switch network</Button>;
              }
              return (
                <div className="flex items-center gap-x-2">
                  <DropdownButton onClick={openChainModal}>
                    <div className="flex items-center gap-x-2">
                      {chain.hasIcon && (
                        <div className="rounded-full h-6 w-6 shrink-0 overflow-hidden">
                          {chain.iconUrl && (
                            <Image alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} width={24} height={24} />
                          )}
                        </div>
                      )}
                      <p className="hidden md:flex items-center">
                        {chain.name}
                        <ChevronIcon />
                      </p>
                    </div>
                  </DropdownButton>
                  <WalletDropdown account={account} />
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
