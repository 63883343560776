'use client';

import Image from 'next/image';
import { ThirdPartyBridge } from '@/hooks/content';
import { useBridgeContext, AppState } from '@/BridgeProvider';
import { ExternalLinkButton, Badge, ExternalLinkWarningModal, Button } from '@/components';
import clsx from 'clsx';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { ReactElement } from 'react';

export const BridgeOption = ({
  logoSrc,
  name,
  transactionEstimatedTimeDeposit,
  transactionEstimatedTimeWithdrawal,
  selected,
  native,
  isAvailable = true,
  onClick,
}: Omit<ThirdPartyBridge, 'link' | 'id' | 'supportedTokenCodes' | 'supportedNetworkIds'> & {
  selected?: boolean;
  native?: boolean;
  isAvailable?: boolean;
  onClick?: () => void;
}) => {
  const { state } = useBridgeContext();
  const { localize } = useLocalizedContentfulStrings();

  return (
    <div
      onClick={onClick}
      className={clsx(
        selected ? 'border-primary-500' : 'border-neutral-950',
        isAvailable ? 'bg-neutral-1000' : 'bg-blue-600 bg-opacity-[8%]',
        'border relative flex items-center justify-between p-4 uppercase rounded-lg font-medium text-xs'
      )}
    >
      <div>
        <div className="flex gap-x-2">
          <Image className="saturate-0" src={logoSrc} width={20} height={20} alt={name} />
          <p className="text-neutral-100 text-base">{name}</p>
        </div>
        <p className="mt-2">
          Usually takes{' '}
          {state.view === 'deposit' ? transactionEstimatedTimeDeposit : transactionEstimatedTimeWithdrawal}.
        </p>
        <p>{native ? localize('modeBridgeDesc') : localize('thirdPartyBridgeDesc')}</p>
      </div>
      {!native && <ExternalLinkButton />}
      {!isAvailable && (
        <Badge style={{ color: '#00B2EE', backgroundColor: '#404040' }} className="absolute top-4 right-4">
          Token not available
        </Badge>
      )}
    </div>
  );
};

export const BridgeOptionCompact = ({ logoSrc, link, name }: { logoSrc: string; link: string; name: string }) => {
  return (
    <ExternalLinkWarningModal href={link} className="flex flex-col items-center w-full">
      <div className="w-full p-2 bg-neutral-900 rounded-lg border border-neutral-600 flex justify-between items-center">
        <div className="flex items-center">
          <Image className="saturate-0" src={logoSrc} width={24} height={24} alt={name} />
          <p className="ml-2 text-neutral-100">{name}</p>
        </div>
        <ExternalLinkButton
          className="flex items-center justify-center w-6 h-6 rounded bg-neutral-900 text-neutral-100"
          href={link}
        />
      </div>
    </ExternalLinkWarningModal>
  );
};

export const BridgeOptionExpanded = ({
  logoSrc,
  link,
  name,
  buttonText,
}: {
  logoSrc: string;
  link: string;
  name?: string;
  buttonText: string;
}) => {
  return (
    <ExternalLinkWarningModal href={link} className="flex flex-col items-center">
      <div className="flex flex-col gap-y-4 w-full p-4 bg-neutral-900 rounded-lg border border-neutral-600 justify-between items-center">
        <div className="flex justify-center w-full">
          <Image className="saturate-0 w-auto" src={logoSrc} width={24} height={24} alt={name || ''} />
          {name ? <p className="mt-2 text-neutral-100">{name}</p> : null}
        </div>
        <Button className="w-full">
          {buttonText}
          <Image className="ml-2" src="/assets/icons/external_link.svg" alt="svg" width={24} height={24} />
        </Button>
      </div>
    </ExternalLinkWarningModal>
  );
};

export const format3rdPartyLink = (link: string, state: AppState) => {
  return link
    .replace('{currency}', state.selectedCurrency.toUpperCase())
    .replace('?symbol={symbol}', `?amount=${state.inputValue}&symbol=${state.selectedCurrency}`);
};

export const BridgeInfoBanner = ({ logoSrc, children }: { logoSrc?: string; children: ReactElement }) => {
  return (
    <div className="border-primary-500 bg-neutral-1000 border relative flex items-center p-4 rounded-lg text-sm text-[#ADAEB1]">
      {logoSrc ? (
        <div className="min-w-4 mr-4">
          <Image className="saturate-0" src={logoSrc} width={20} height={20} alt="info" />
        </div>
      ) : null}
      <div>{children}</div>
    </div>
  );
};
