import Image from 'next/image';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const Spinner = ({ className, size = 48 }: { className?: string; size?: number }) => {
  return <Image src="/spin.svg" width={size} height={size} className={clsx(className, 'mx-auto')} alt="Spinner" />;
};

export const CircleLoader = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <Image
      src="/circleLoader.svg"
      width={size}
      height={size}
      className={twMerge('animate-spin mx-auto', className)}
      alt="Circle Spinner"
    />
  );
};
