import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

export const Warning = ({
  text,
  className,
  icon,
  iconWidth = 24,
  iconHeight = 24,
}: {
  text?: string;
  className?: string;
  icon?: string;
  iconWidth?: number;
  iconHeight?: number;
}) => {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center gap-x-4 p-4 border border-primary-500 text-primary-500 rounded-xl text-sm',
        className
      )}
    >
      <Image
        width={iconWidth}
        height={iconHeight}
        src={`${icon || '/assets/icons/alert-triangle-icon.svg'}`}
        alt="Disclaimer"
      />
      <p>{text}</p>
    </div>
  );
};
