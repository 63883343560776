'use client';

import { ClaimIcon, ExternalArrowIcon, InitiateWithdrawalIcon, ProveIcon, WaitIcon } from '@/components';
import localConfig from '@/config';
import { ExplorerTransaction } from '@/types/expoler';
import { MessageStatus } from '@eth-optimism/sdk';
import clsx from 'clsx';
import { addDays, addHours, format } from 'date-fns';
import { FC, useMemo } from 'react';

interface Props {
  status: MessageStatus;
  transaction: ExplorerTransaction | undefined;
}

export const WithdrawPathProgress: FC<Props> = ({ status, transaction }) => {
  const steps = useMemo(() => {
    return [
      {
        label: 'Initiate Withdrawal',
        isPassed: status >= MessageStatus.UNCONFIRMED_L1_TO_L2_MESSAGE,
        isActive: status === MessageStatus.UNCONFIRMED_L1_TO_L2_MESSAGE,
        icon: <InitiateWithdrawalIcon />,
        timestamp: transaction?.timestamp,
        block: transaction?.block,
        hash: transaction?.hash,
      },
      {
        label: 'Prove withdrawal',
        isPassed: status >= MessageStatus.READY_TO_PROVE,
        isActive: status === MessageStatus.READY_TO_PROVE,
        icon: <ProveIcon />,
        est: '~1 hour',
        sub: 'Prove transaction in your wallet',
      },
      {
        label: 'Finalise withdrawal',
        isPassed: status >= MessageStatus.IN_CHALLENGE_PERIOD,
        isActive: status === MessageStatus.IN_CHALLENGE_PERIOD,
        icon: <WaitIcon />,
        est: '~7 days',
        sub: 'Finalize transaction in your wallet',
      },
      {
        label: status === MessageStatus.RELAYED ? 'Claimed' : 'Claim Withdrawal',
        isPassed: status >= MessageStatus.READY_FOR_RELAY,
        isActive: status === MessageStatus.READY_FOR_RELAY,
        icon: <ClaimIcon />,
        hashEtherscan: transaction?.op_withdrawals.find((t) => t.status === 'Relayed')?.l1_transaction_hash,
      },
    ];
  }, [status, transaction?.block, transaction?.hash, transaction?.op_withdrawals, transaction?.timestamp]);

  const getEstimatedDate = (date: string, distance: string) => {
    const number = distance.match(/\d+/);

    if (!number) return '';
    if (distance.includes('days')) {
      return format(addDays(date, parseInt(number[0])), 'dd MMM yyyy');
    }
    if (distance.includes('hours')) {
      return format(addHours(date, parseInt(number[0])), 'dd MMM yyyy, hh:mm a');
    }
    return '';
  };

  return (
    <div className="flex flex-col text-xs">
      {steps.map(({ label, est, sub, hashEtherscan, isActive, icon, isPassed, timestamp, block, hash }, ix) => {
        return (
          <div
            key={`${label}-${ix}`}
            className={clsx(
              isActive && 'bg-neutral-800',
              'uppercase w-full flex items-center justify-between gap-4 mb-2 pl-1 py-2 pr-4 rounded-lg text-neutral-500 z-10'
            )}
          >
            <div className={clsx(!sub && 'items-center', 'flex gap-x-2')}>
              <div className={clsx(isActive || (isPassed && 'text-primary-500'))}>{icon}</div>
              <div>
                <div className="text-neutral-100 font-medium">{label}</div>
                <div>
                  {timestamp && format(new Date(timestamp), 'dd MMM yyyy, hh:mm a')}
                  {block && <div>Block: {block}</div>}
                  {sub && <div>{sub}</div>}
                </div>
              </div>
            </div>
            {hash && (
              <div>
                <a href={`${localConfig.explorerUrl}/tx/${hash}`} target="_blank" rel="noopener noreferrer">
                  <ExternalArrowIcon />
                </a>
              </div>
            )}
            {hashEtherscan && (
              <div>
                <a
                  href={`${localConfig.etherscanExplorerUrl}/tx/${hashEtherscan}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalArrowIcon />
                </a>
              </div>
            )}
            {est && transaction?.timestamp && (
              <div className="flex flex-col items-end">
                <p className={clsx(isActive && 'text-neutral-100')}>{est}</p>
                {isActive && <p className="text-[10px]">{getEstimatedDate(transaction.timestamp, est)}</p>}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
