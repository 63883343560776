'use client';

import { useBridgeContext } from '@/BridgeProvider';
import { InfoSection } from '@/components/Bridge/InfoSection';
import Button from '@/components/Button';
import { NetworkIndicator } from '@/components/ReviewWithdrawal/components/NetworkIndicator';
import { WithdrawPath } from '@/components/ReviewWithdrawal/components/WithdrawPath';
import localConfig from '@/config';
import { useEstimateGas } from '@/hooks/bridge/useEstimateGas';
import { FC } from 'react';
import { useToggle } from 'react-use';
import { useAccount } from 'wagmi';

interface Props {
  onSubmit: () => Promise<void | null | `0x${string}`>;
  isLoading: boolean;
}

export const ReviewWithdrawalContent: FC<Props> = ({ onSubmit, isLoading }) => {
  const [confirmed, toggleConfirmed] = useToggle(false);
  const { state } = useBridgeContext();
  const { chain } = useAccount();
  const { value: estimatedGas, loading: loadingGas } = useEstimateGas();

  function getLabel() {
    if (chain?.id !== parseInt(localConfig.l2ChainId)) {
      return 'Switch network';
    }

    return isLoading ? 'Waiting for confirmation...' : 'Initiate Withdrawal';
  }

  return (
    <div className="flex w-full flex-col justify-between">
      <div className="flex gap-4">
        <div>
          <div>
            {state.inputValue} {state.selectedCurrency}
          </div>
          <div className="text-neutral-500 text-sm">Amt. for withdrawal</div>
        </div>
        <NetworkIndicator source="From" chainId={localConfig.l2ChainId} className="ml-auto" />
        <NetworkIndicator source="To" chainId={localConfig.l1ChainId} />
      </div>
      <div className="divide-neutral-100 w-full h-[1px] bg-neutral-100 my-6 opacity-50" />
      <WithdrawPath active="" />
      <a
        href="https://docs.mode.network/mode-mainnet/bridge/bridge-from-mode"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        Learn more about the withdrawal process from Mode
      </a>
      <div className="divide-neutral-100 w-full h-[1px] bg-neutral-100 mt-6 opacity-50" />
      <InfoSection className="mt-8" estimatedGas={estimatedGas} loadingGas={loadingGas} />

      <div className="flex items-center mb-4 mt-8">
        <input
          id="confirmWithdraw"
          type="checkbox"
          value=""
          onChange={() => toggleConfirmed(!confirmed)}
          checked={confirmed}
          className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor="confirmWithdraw" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          I understand it will take ~7 days until my my funds are claimable on Ethereum Mainnet
        </label>
      </div>

      <Button
        onClick={onSubmit}
        className="mt-6 w-full flex items-center justify-center"
        disabled={!confirmed || isLoading}
      >
        {getLabel()}
      </Button>
    </div>
  );
};
