import { twMerge } from 'tailwind-merge';

export const Divider = ({
  type = 'horizontal',
  className,
}: {
  type?: 'vertical' | 'horizontal';
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        type === 'horizontal' ? 'w-full h-[1px] my-2' : 'w-[1px] h-full mx-2',
        'bg-neutral-900 shrink-0',
        className
      )}
    />
  );
};
