interface CacheData {
  timestamp: number;
  data: Record<string, { usd: number }>;
}

export async function getTokensPrice(): Promise<Record<string, number>> {
  const cache = await caches.open('eth-price');
  const cacheTime = 300000; // 300000 milliseconds = 5 minutes
  const tokenIds = [
    'ethereum',
    'tether',
    'usd-coin',
    'wrapped-bitcoin',
    'wrapped-eeth',
    'mode',
    'renzo-restaked-eth',
    'merlin-s-seal-btc',
    'stakestone-ether',
    'dola-usd',
    'wrapped-steth',
    'savings-dai',
    'xlink-bridged-btc-stacks',
    'ethena-usde',
    'alexgo',
    'universal-btc',
  ];
  const cacheKey = `https://api.coingecko.com/api/v3/simple/price?ids=${tokenIds.join(',')}&vs_currencies=usd`;

  const cachedResponse = await cache.match(cacheKey);

  if (cachedResponse) {
    const cacheData: CacheData = (await cachedResponse.json()) as CacheData;
    const currentTime = new Date().getTime();

    // Check if the cached data is still valid (e.g., less than 5 minutes old)
    if (currentTime - cacheData.timestamp <= cacheTime) {
      // 300000 milliseconds = 5 minutes
      return {
        eth: cacheData.data.ethereum.usd,
        usdt: cacheData.data.tether.usd,
        usdc: cacheData.data['usd-coin'].usd,
        wbtc: cacheData.data['wrapped-bitcoin'].usd,
        weeth: cacheData.data['wrapped-eeth'].usd,
        mode: cacheData.data.mode.usd,
        ezeth: cacheData.data['renzo-restaked-eth'].usd,
        mbtc: cacheData.data['merlin-s-seal-btc'].usd,
        cstone: cacheData.data['stakestone-ether'].usd,
        dola: cacheData.data['dola-usd'].usd,
        wsteth: cacheData.data['wrapped-steth'].usd,
        sdai: cacheData.data['savings-dai'].usd,
        abtc: cacheData.data['xlink-bridged-btc-stacks'].usd,
        usde: cacheData.data['ethena-usde'].usd,
        alex: cacheData.data['alexgo'].usd,
        unibtc: cacheData.data['universal-btc'].usd,
      };
    }
  }

  const resp = await fetch(cacheKey);

  const respJSON = (await resp.json()) as CacheData['data'];

  if (!resp.ok) {
    const errorMessage = typeof respJSON.error === 'string' ? respJSON.error : JSON.stringify(respJSON.error);

    throw new Error('[Coingecko] cannot make request to the API server: ' + errorMessage);
  }

  // Store the response along with a timestamp
  const cacheData = {
    timestamp: new Date().getTime(),
    data: respJSON,
  };
  await cache.put(cacheKey, new Response(JSON.stringify(cacheData)));

  return {
    eth: respJSON.ethereum.usd,
    usdt: respJSON.tether.usd,
    usdc: respJSON['usd-coin'].usd,
    wbtc: respJSON['wrapped-bitcoin'].usd,
    weeth: respJSON['wrapped-eeth'].usd,
    mode: respJSON.mode.usd,
    ezeth: respJSON['renzo-restaked-eth'].usd,
    mbtc: respJSON['merlin-s-seal-btc'].usd,
    cstone: respJSON['stakestone-ether'].usd,
    dola: respJSON['dola-usd'].usd,
    wsteth: cacheData.data['wrapped-steth'].usd,
    sdai: cacheData.data['savings-dai'].usd,
    abtc: cacheData.data['xlink-bridged-btc-stacks'].usd,
    usde: cacheData.data['ethena-usde'].usd,
    alex: cacheData.data['alexgo'].usd,
    unibtc: cacheData.data['universal-btc'].usd,
  };
}
