'use client';

import l1BridgeAbi from '@/abis/l1Bridge.json';
import localConfig from '@/config';
import l1BridgeArtifact from '@/constants/abis/L1StandardBridge.json';
import { BigNumber } from 'ethers';
import { useCallback } from 'react';
import { Address } from 'viem';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';

export function useSimulateNativeBridgeContractCall() {
  const publicClient = usePublicClient();
  const { address } = useAccount();

  return useCallback(
    (value: bigint, args: Array<number | string>, gasLimit: bigint, recipient?: string) => {
      return publicClient?.simulateContract({
        address: localConfig.l1BridgeAddress as `0x${string}`,
        abi: l1BridgeArtifact.abi,
        functionName: recipient ? 'depositETHTo' : 'depositETH',
        args: recipient ? [recipient, ...args] : args,
        account: address,
        value,
        gas: gasLimit,
      });
    },
    [address, publicClient]
  );
}

export function useDirectNativeBridgeContractCall() {
  const { data: walletClient } = useWalletClient();
  const { address } = useAccount();

  return useCallback(
    (value: bigint, args: Array<number | string>, gasLimit: bigint, recipient?: string) => {
      if (!walletClient) {
        return;
      }

      return walletClient.writeContract({
        address: localConfig.l1BridgeAddress as `0x${string}`,
        abi: l1BridgeArtifact.abi,
        functionName: recipient ? 'depositETHTo' : 'depositETH',
        args: recipient ? [recipient, ...args] : args,
        account: address,
        value,
        gas: gasLimit,
      });
    },
    [address, walletClient]
  );
}

export function useSimulateTokenBridgeContractCall() {
  const publicClient = usePublicClient();
  const { address } = useAccount();

  return useCallback(
    (
      args: Array<number | string | undefined | BigNumber>,
      gasLimit: bigint,
      recipient?: string,
      l1BridgeAddress?: Address
    ) => {
      return publicClient?.simulateContract({
        address: l1BridgeAddress ? l1BridgeAddress : (localConfig.l1BridgeAddress as `0x${string}`),
        abi: l1BridgeAbi,
        functionName: recipient ? 'depositERC20To' : 'depositERC20',
        args,
        account: address,
        gas: gasLimit,
      });
    },
    [address, publicClient]
  );
}

export function useDirectTokenBridgeContractCall() {
  const { data: walletClient } = useWalletClient();
  const { address } = useAccount();

  return useCallback(
    (
      args: Array<number | string | undefined | BigNumber>,
      gasLimit: bigint,
      recipient?: string,
      l1BridgeAddress?: Address
    ) => {
      if (!walletClient) {
        return;
      }

      return walletClient.writeContract({
        address: l1BridgeAddress ? l1BridgeAddress : (localConfig.l1BridgeAddress as `0x${string}`),
        abi: l1BridgeAbi,
        functionName: recipient ? 'depositERC20To' : 'depositERC20',
        args,
        account: address,
        gas: gasLimit,
      });
    },
    [address, walletClient]
  );
}
