import { useAccount, useSwitchChain } from 'wagmi';
import { useAsync, useAsyncFn } from 'react-use';

export function useNetworkMismatch(chainId: number) {
  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();

  const [{ loading: switchingNetwork }, handleSwitchNetwork] = useAsyncFn(
    async (newChainId?: number) => {
      return switchChainAsync({
        chainId: newChainId ?? chainId,
      });
    },
    [chainId]
  );

  const { value: isNetworkMismatch, loading } = useAsync(async () => {
    if (!chain?.id) {
      return;
    }

    return chain.id !== chainId;
  }, [chain?.id, chainId]);

  return {
    handleSwitchNetwork,
    isNetworkMismatch,
    switchingNetwork,
    loading,
  };
}
