'use client';

import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export const DropdownButton = ({ onClick, className, children }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx(className, 'border border-neutral-900 rounded-lg p-2 md:px-4 h-fit')}
    >
      {children}
    </button>
  );
};
