import { useAccount } from 'wagmi';
import { useAsync } from 'react-use';
import { getL1SignerCrossChainMessenger } from '@/components/Withdrawals/helpers';
import { TokenBridgeMessage } from '@eth-optimism/sdk';
import { getWstEthCrossChainMessenger } from '@/utils/getCrossChainMessenger';
import uniqBy from 'lodash.uniqby';

export function useWithdrawalsByAddress(): {
  isLoading: boolean;
  data: TokenBridgeMessage[];
} {
  const { address } = useAccount();

  const { value, loading } = useAsync(async () => {
    if (!address) {
      return;
    }

    try {
      const l1Messenger = await getL1SignerCrossChainMessenger();
      const wstEthMessanger = await getWstEthCrossChainMessenger();
      const bridgeMessages = await l1Messenger.getWithdrawalsByAddress(address);
      const wstEthMessages = await wstEthMessanger.getWithdrawalsByAddress(address);

      return uniqBy([...bridgeMessages, ...wstEthMessages], 'transactionHash').sort((a, b) => {
        if (a.blockNumber > b.blockNumber) {
          return -1;
        }
        if (a.blockNumber < b.blockNumber) {
          return 1;
        }
        return 0;
      });
    } catch (e) {
      console.error(e);
    }
  }, [address]);

  return {
    data: value ?? [],
    isLoading: loading,
  };
}
