'use client';

import { useBridgeContext } from '@/BridgeProvider';
import { ArrowIcon, Divider, InputSection, SectionContainer } from '@/components';
import { BridgeInfoBanner } from '@/components/Bridge/BridgeOption';
import { useNativeDeposit, useTokenDeposit } from '@/components/Bridge/hooks';
import { InfoSection } from '@/components/Bridge/InfoSection';
import { RecepientSection } from '@/components/Bridge/RecepientSection';
import { SubmitBridgeButton } from '@/components/Bridge/SubmitBridgeButton';
import { Tabs } from '@/components/Bridge/Tabs';
import { BridgeOptions } from '@/components/Bridge/ThirdPartyBridges';
import { TokenSelector } from '@/components/Bridge/TokenSelector';
import localConfig from '@/config';
import { currencyOptions } from '@/constants';
import { useEstimateGas } from '@/hooks/bridge/useEstimateGas';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { useEthersSigner } from '@/utils/ethers';
import { getCrossChainMessenger } from '@/utils/getCrossChainMessenger';
import { providers } from '@/utils/getTokenBalances';
import standart_bridge_json from '@eth-optimism/contracts/artifacts/contracts/L2/messaging/L2StandardBridge.sol/L2StandardBridge.json';
import clsx from 'clsx';
import { ethers, utils } from 'ethers';
import { IBM_Plex_Sans } from 'next/font/google';
import { useRouter, useSearchParams } from 'next/navigation';
import { useAsyncFn } from 'react-use';
import { useAccount, useSwitchChain, useWalletClient } from 'wagmi';

export const SANS = IBM_Plex_Sans({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '500', '600'],
});

export const Bridge = () => {
  const source = 'from';
  const router = useRouter();
  const searchParams = useSearchParams();
  const { address, chain } = useAccount();
  const { localize } = useLocalizedContentfulStrings();
  const getL2Signer = useEthersSigner({ chainId: parseInt(localConfig.l2ChainId) });
  const hasNotBridged = searchParams.get('bridged') === 'false';
  const { state } = useBridgeContext();
  const { data: walletClient } = useWalletClient();
  const { value: estimatedGas, loading: loadingGas } = useEstimateGas();
  const { isThirdPartyOnlyToken, isThirdPartyOnlyNetwork } = useThirdPartyOnlyBridge(source);

  const { nativeDeposit } = useNativeDeposit();
  const { tokenDeposit } = useTokenDeposit();
  const { switchChainAsync } = useSwitchChain();

  const handleDeposit = () => {
    try {
      if (state.selectedCurrency === 'ETH') {
        return nativeDeposit();
      } else {
        return tokenDeposit();
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleWithDraw = () => {
    if (state.selectedCurrency === 'ETH') {
      return nativeWithdraw();
    } else {
      return tokenWithdraw();
    }
  };

  const getL2SignerCrossChainMessenger = async () => {
    const l2Signer = await getL2Signer();
    const l1provider = providers.l1;

    return getCrossChainMessenger(null, l2Signer, l1provider, null);
  };

  const nativeWithdraw = async () => {
    if (!walletClient) return;

    try {
      const l2Messenger = await getL2SignerCrossChainMessenger();

      const amount = utils.parseEther(state.inputValue.toString());

      const options = state.recipientValue ? { recipient: state.recipientValue } : undefined;

      await l2Messenger.withdrawETH(amount, options);
    } catch (error) {
      console.log(error, 'error');
      return error;
    }
  };

  const tokenWithdraw = async () => {
    if (!state.inputValue) return;

    const selectedToken = currencyOptions.find((option) => option.label === state.selectedCurrency);

    const l2Messenger = await getL2SignerCrossChainMessenger();

    const amount = ethers.utils.parseUnits(state.inputValue.toString(), state.from.token.decimals);

    const options = state.recipientValue ? { recipient: state.recipientValue } : undefined;

    if (selectedToken?.label === 'wstETH') {
      await walletClient?.writeContract({
        address: selectedToken?.l2Bridge as `0x${string}`,
        abi: standart_bridge_json.abi,
        functionName: 'withdraw',
        args: [selectedToken?.l2, amount, 0, '0x00'],
        account: address,
      });
    } else {
      await l2Messenger.withdrawERC20(selectedToken?.l1 as string, selectedToken?.l2 as string, amount, options);
    }
  };

  const [{ loading }, handleFunds] = useAsyncFn(async () => {
    if (state.view === 'deposit') {
      if (chain?.id !== parseInt(localConfig.l1ChainId)) {
        return switchChainAsync({
          chainId: parseInt(localConfig.l1ChainId),
        });
      }
      return handleDeposit();
    } else {
      if (chain?.id !== parseInt(localConfig.l2ChainId)) {
        return switchChainAsync({
          chainId: parseInt(localConfig.l2ChainId),
        });
      }
      return handleWithDraw();
    }
  }, [state, chain, handleDeposit, handleWithDraw]);

  return (
    <SectionContainer className="w-full md:px-8 px-4 py-8 gap-y-4">
      <Tabs />
      <TokenSelector label="" source={source} />
      <Divider type="horizontal" />
      <div className="flex flex-col gap-y-4">
        <BridgeOptions />
        <div>
          <div className="flex items-baseline gap-x-1 mb-2">
            <h3 className="text-neutral-100 font-semibold text-base uppercase">MODE BRIDGE</h3>
            <p className="text-xs">(Usually takes {state.view === 'deposit' ? '~4 mins' : '~7 days'})</p>
          </div>
          <p className={clsx(SANS.className, 'text-neutral-600 text-sm mb-3')}>{localize('modeBridgeDesc')}</p>
        </div>
      </div>
      {!isThirdPartyOnlyToken && !isThirdPartyOnlyNetwork ? (
        <>
          <InputSection className="mt-2" estimatedGas={estimatedGas} loadingGas={loadingGas} />
          <RecepientSection type="red" />
          <InfoSection estimatedGas={estimatedGas} loadingGas={loadingGas} />
        </>
      ) : null}
      {isThirdPartyOnlyToken || isThirdPartyOnlyNetwork ? (
        <BridgeInfoBanner logoSrc="/assets/icons/mode_white.svg">
          <>
            <p className={SANS.className}>Token is not available for withdrawal via Mode bridge.</p>
            <p className={SANS.className}>Please use a third party option listed above.</p>
          </>
        </BridgeInfoBanner>
      ) : (
        <SubmitBridgeButton
          source={source}
          onSubmitHandler={async () => {
            return handleFunds();
          }}
          isLoading={loading}
        />
      )}
      {hasNotBridged && (
        <button
          className="flex items-center justify-center space-x-2 rounded uppercase text-neutral-100 font-medium
                    border border-yellow-500 py-2 px-4 w-full min-w-40 sm:w-unset"
          onClick={() => router.replace('/early?bridged=true')}
        >
          <p>{localize('haveAlreadyBridged')}</p>
          <ArrowIcon />
        </button>
      )}
    </SectionContainer>
  );
};

export default Bridge;
