import { ReactNode, CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

export const Badge = ({
  children,
  className,
  style,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      style={style}
      className={twMerge(
        'text-neutral-100 h-6 min-w-6 text-xs bg-neutral-900 flex items-center justify-center px-1 uppercase font-medium whitespace-nowrap rounded',
        className
      )}
    >
      {children}
    </div>
  );
};
