import { useAsync } from 'react-use';
import { useFeeData } from 'wagmi';
import localConfig from '@/config';

import { toOptionalFixed } from '@/utils/format';
import { getTokensPrice } from '@/services/coinGecko';

export function useEstimateGas() {
  const { data, isError, isLoading } = useFeeData({
    chainId: parseInt(localConfig.l1ChainId),
    formatUnits: 'ether',
  });

  const { value, loading } = useAsync(async () => {
    if (isError || isLoading || !data || !data.formatted.gasPrice) {
      return;
    }

    const price = await getTokensPrice();

    const ethValue = +data.formatted.gasPrice * parseInt(localConfig.ethDepositGasLimit);

    return {
      eth: ethValue,
      usd: toOptionalFixed(ethValue * price.eth, 2),
    };
  }, [data, isError, isLoading]);

  return {
    value,
    loading,
  };
}
