import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

/**
 * Shows a Jazzicon for the provided wallet address
 * @param {*} props
 * @returns <Jazzicons /> JSX Elemenet
 */

export const WalletIcon = ({ seed, size }: { seed: string; size?: number }) => {
  if (size) return <Jazzicon seed={jsNumberForAddress(seed)} diameter={size} />;

  return <Jazzicon seed={jsNumberForAddress(seed)} />;
};
