'use client';

import { ArrowIcon, Button, ChevronIcon, Divider, Spinner } from '@/components';
import { InlineLoader } from '@/components/InlineLoader';
import { WithdrawPathProgress } from '@/components/ReviewWithdrawal/components/WithdrawPath/WithdrawPathProgress';
import { HistoryWithdrawalItem } from '@/components/Withdrawals/HistoryWithdrawalItem';
import localConfig from '@/config';
import { useWithdrawalDetails } from '@/hooks/bridge/useWithdrawalDetails';
import { useNetworkMismatch } from '@/hooks/wallet/useNetworkMismatch';
import { toOptionalFixed } from '@/utils/format';
import { getNetworkIcon } from '@/utils/wallet';
import { MessageStatus, TokenBridgeMessage } from '@eth-optimism/sdk';
import clsx from 'clsx';
import Image from 'next/image';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';

export const WithdrawalItem = ({ message, compact }: { message: TokenBridgeMessage; compact?: boolean }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const { data, isLoading, submitting, handleFinalize, handleProve } = useWithdrawalDetails(message);

  const { isNetworkMismatch, handleSwitchNetwork, switchingNetwork } = useNetworkMismatch(
    parseInt(localConfig.l1ChainId)
  );

  if (isLoading) return <Spinner size={24} />;

  if (data && data.statusCode === MessageStatus.RELAYED) return <HistoryWithdrawalItem compact={compact} data={data} />;

  function renderSwitchNetworkButton() {
    return (
      <Button size="sm" onClick={() => handleSwitchNetwork()}>
        {switchingNetwork ? <InlineLoader label="Processing..." className="text-black text-xs" /> : 'Switch Network'}
      </Button>
    );
  }

  function renderAction() {
    switch (data?.statusCode) {
      case MessageStatus.READY_TO_PROVE: {
        if (isNetworkMismatch) {
          return renderSwitchNetworkButton();
        }
        return (
          <Button
            size="sm"
            className="rounded-lg px-4"
            onClick={async () => {
              const res = await handleProve();

              if (res && 'hash' in res) {
                toast.success('Successfully proved withdraw transaction');
              } else if (res && 'reason' in res) {
                toast.error(res.reason as string);
              }
            }}
          >
            {submitting ? <InlineLoader label="Processing..." className="text-black text-xs" /> : 'Prove'}
          </Button>
        );
      }
      case MessageStatus.READY_FOR_RELAY: {
        if (isNetworkMismatch) {
          return renderSwitchNetworkButton();
        }
        return (
          <Button
            size="sm"
            className="rounded-lg px-4"
            onClick={async () => {
              const res = await handleFinalize();

              if (res && 'hash' in res) {
                toast.success('Successfully finalized withdraw transaction');
              } else if (res && 'reason' in res) {
                toast.error(res.reason as string);
              }
            }}
          >
            {submitting ? <InlineLoader label="Processing..." className="text-black text-xs" /> : 'Claim'}
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <div className="flex flex-col w-full p-2 items-center rounded-lg bg-neutral-900 mt-2">
      <div
        role="button"
        onClick={() => toggleIsOpen()}
        className="w-full flex items-center justify-between cursor-pointer"
      >
        <div className="flex items-center gap-x-8">
          <div className="flex items-center">
            <Image
              className="rounded-full"
              src={getNetworkIcon(+localConfig.l2ChainId)}
              alt="l2ChainId"
              width={24}
              height={24}
            />
            <ArrowIcon size={24} className="text-neutral-100" />
            <Image
              className="rounded-full"
              src={getNetworkIcon(+localConfig.l1ChainId)}
              alt="l1ChainId"
              width={24}
              height={24}
            />
          </div>
          <div className="flex flex-col items-start whitespace-nowrap">
            {isLoading ? null : (
              <>
                <p className="text-neutral-100 font-medium">
                  {data ? toOptionalFixed(data.amount, 6) : ''} {data?.token?.symbol}
                </p>
                <p className={compact ? 'text-[10px]' : 'text-sm'}>${data?.usdAmount}</p>
              </>
            )}
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="flex justify-center items-center">{renderAction()}</div>
          <ChevronIcon
            className={clsx(isOpen ? 'text-yellow-500 rotate-180 transition duration-400' : 'text-neutral-100')}
          />
        </div>
      </div>
      {isOpen && data && (
        <div className="flex flex-col w-full">
          <Divider className="shrink-0 bg-neutral-800" />
          <WithdrawPathProgress status={data.statusCode || 0} transaction={data.transaction} />
        </div>
      )}
    </div>
  );
};

export default WithdrawalItem;
