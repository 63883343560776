import { FC } from 'react';
import { NETWORKS } from '@/constants/networks';
import localConfig from '@/config';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

interface Props {
  chainId: string;
  source: 'From' | 'To';
  className?: string;
}

export const NetworkIndicator: FC<Props> = ({ chainId, source, className }) => {
  const network = NETWORKS[localConfig.modeEnv].find((item) => item.chainId === +chainId);

  if (!network) {
    return null;
  }

  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      <div className="flex items-center gap-1">
        <Image src={network.icon} alt="svg" width={24} height={24} />
        <span className="whitespace-nowrap">{network.name}</span>
      </div>
      <div className="text-neutral-500 text-sm">{source}</div>
    </div>
  );
};
