import { InlineLoader } from '@/components/InlineLoader';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  estimatedGas:
    | {
        eth: number;
        usd: string;
      }
    | undefined;
  loadingGas: boolean;
}

export const InfoSection: FC<Props> = ({ className = '', estimatedGas, loadingGas }) => {
  function renderGasInfo() {
    if (loadingGas) {
      return (
        <div className="flex items-center justify-between text-neutral-100">
          <div>Gas fee (est.):</div>
          <InlineLoader />
        </div>
      );
    }

    if (estimatedGas) {
      return (
        <div>
          <div className="flex md:flex-row md:items-center md:justify-between mt-4 text-base">
            <div className="text-neutral-100">Gas fee (est.):</div>
            <div className="flex items-center gap-x-2">
              <div className="text-neutral-500 text-xs md:text-right">{`~$${estimatedGas.usd} USD`}</div>
              <div className=" md:text-right text-neutral-100">{`~${estimatedGas.eth} ETH`}</div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  return <div className={twMerge(className)}>{renderGasInfo()}</div>;
};
