'use client';

import WithdrawalItem from '@/components/Withdrawals/WithdrawalItem';
import { useWithdrawalsByAddress } from '@/hooks/bridge/useWithdrawalsByAddress';
import { Spinner, Button, ArrowIcon } from '@/components';
import { useRouter } from 'next/navigation';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const WithdrawalList = () => {
  const { data, isLoading } = useWithdrawalsByAddress();
  const router = useRouter();
  const { localize } = useLocalizedContentfulStrings();

  return (
    <div className="w-full min-h-[343px]">
      <div className="flex justify-between items-center mb-4">
        <p className="uppercase text-xs font-medium">Pending transactions</p>
        <Button
          onClick={() => router.push('/transactions')}
          className="flex items-center gap-x-1"
          variant="secondary"
          size="sm"
        >
          {localize('seeAllTransactions')}
          <ArrowIcon />
        </Button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : data?.length !== 0 ? (
        <>
          {data.slice(0, 5).map((item, ix) => {
            return <WithdrawalItem compact key={`${item.transactionHash}-${ix}`} message={item} />;
          })}
        </>
      ) : (
        <div className="flex w-full items-center justify-center">
          <p className="text-xs">No pending withdrawals</p>
        </div>
      )}
    </div>
  );
};
