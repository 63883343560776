import { useAccount } from 'wagmi';
import { useEffect } from 'react';
import { clearAirdropDataFromLocalStorage } from '@/hooks/airdrop/appCache';
import { useQueryClient } from '@tanstack/react-query';

const WALLET_CONNECTION_STATUS = 'WALLET_CONNECTION_STATUS';

enum Status {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export function useConnectionStatus() {
  const { isConnected } = useAccount();
  const queryClient = useQueryClient();

  useEffect(() => {
    const lsConnectionStatus = (localStorage.getItem(WALLET_CONNECTION_STATUS) as Status) ?? Status.DISCONNECTED;

    if (isConnected && lsConnectionStatus === Status.DISCONNECTED) {
      localStorage.setItem(WALLET_CONNECTION_STATUS, Status.CONNECTED);
    } else if (!isConnected && lsConnectionStatus === Status.CONNECTED) {
      // clear everything
      clearAirdropDataFromLocalStorage();
      queryClient.removeQueries();
      localStorage.setItem(WALLET_CONNECTION_STATUS, Status.DISCONNECTED);
      window.location.reload();
    }
  }, [isConnected, queryClient]);
}
