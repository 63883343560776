'use client';

import { useLocalizationContext } from '@/app/context';
import { Badge, Spinner } from '@/components';
import { useFaqItems } from '@/hooks/content';
import Image from 'next/image';
import { useState } from 'react';

type AccordionItem = {
  title?: string;
  content?: string;
};

export const Faq = () => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const { data: faqItems, isLoading: isFaqItemsLoading } = useFaqItems();
  const { locale } = useLocalizationContext();

  const handleClick = (index: number) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index) ? prevIndexes.filter((i) => i !== index) : [...prevIndexes, index]
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      {isFaqItemsLoading ? (
        <Spinner />
      ) : (
        <>
          {Array.isArray(faqItems) &&
            faqItems.map((item, index) => (
              <FaqItem
                item={{ title: item.title[locale], content: item.content[locale] }}
                key={`${item.title[locale]}-${index}`}
                isActive={activeIndexes.includes(index)}
                onClick={handleClick}
                index={index}
              />
            ))}
        </>
      )}
    </div>
  );
};

const FaqItem = ({
  item,
  index,
  onClick,
  isActive,
}: {
  item: AccordionItem;
  index: number;
  onClick: (index: number) => void;
  isActive: boolean;
}) => {
  const renderFormattedText = (text: string) => {
    // Regular expression to match links in the format [text](url)
    const linkRegex = /\[(.*?)\]\((.*?)\)/g;

    // Replace the matched links with HTML anchor tags
    return text
      .replace(linkRegex, '<a href="$2" target="_blank" style="text-decoration:underline;">$1</a>')
      .replace(/\n/g, '<br>');
  };

  return (
    <div className="px-8 py-9 bg-neutral-950 rounded-lg">
      <div onClick={() => onClick(index)} className="flex md:items-center items-start justify-between cursor-pointer">
        <div className="flex md:items-center items-start gap-x-4">
          <Badge>{index + 1}</Badge>
          <p className="text-neutral-100 uppercase text-lg font-bold">{item.title}</p>
        </div>
        <div className="flex items-center justify-center bg-neutral-900 rounded shrink-0 ml-3">
          <Image
            alt="FAQ"
            src={isActive ? '/assets/icons/minus-icon.svg' : '/assets/icons/plus-icon.svg'}
            width={24}
            height={24}
          />
        </div>
      </div>
      {isActive && item.content && (
        <div
          className="mt-2 text-neutral-600"
          dangerouslySetInnerHTML={{ __html: renderFormattedText(item.content) }}
        />
      )}
    </div>
  );
};
