'use client';
import { useBridgeContext } from '@/BridgeProvider';
import { FC, KeyboardEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import fromExponential from 'from-exponential';
import { AmountInputColorTheme } from '@/constants';

interface Props {
  estimatedGas:
    | {
        eth: number;
        usd: string;
      }
    | undefined;
  loadingGas: boolean;
  error?: boolean;
  type?: 'red' | 'orange';
}

function trimDecimalsOverflow(n: string, decimals: number) {
  n += '';

  if (n.indexOf('.') === -1) return n;

  const arr = n.split('.');
  const fraction = arr[1].substr(0, decimals);

  return arr[0] + '.' + fraction;
}

const AmountInput: FC<Props> = ({ error, estimatedGas, type = 'orange' }) => {
  const { dispatch, state } = useBridgeContext();

  const layer = state.view === 'deposit' ? 'L1_balances' : 'L2_balances';
  const allowedDecimals = state['from'].token?.decimals;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Prevent 'e', '+', '-' from being entered
    if (event.key === 'e' || event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <div className="relative flex items-center w-full">
      <input
        type="number"
        min={0}
        className={twMerge(
          'text-neutral-100 bg-neutral-900 border-neutral-600 rounded-lg outline-none py-3 px-4 border w-full text-sm sm:text-base min-h-[48px]',
          clsx({
            [AmountInputColorTheme[type].border]: error,
            [AmountInputColorTheme[type].text]: error,
            [AmountInputColorTheme[type].background]: error,
          })
        )}
        placeholder="Enter amount"
        value={!state.inputValue && state.inputValue !== 0 ? '' : state.inputValue}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          const val = e.target.valueAsNumber;

          dispatch({
            type: 'SET_VALUE',
            payload: val ? fromExponential(Number(trimDecimalsOverflow(val.toString(), allowedDecimals))) : val,
          });
        }}
      />
      <button
        className="absolute right-3 text-xs text-neutral-100 border-neutral-600 border px-2 py-1 rounded"
        onClick={() => {
          const stateInputValue = state[layer][state.selectedCurrency];

          const nextValue =
            state.selectedCurrency === 'ETH' && estimatedGas?.eth ? +stateInputValue * 0.95 : +stateInputValue;

          dispatch({
            type: 'SET_VALUE',
            payload: nextValue < 0 ? 0 : nextValue,
          });
        }}
      >
        Max
      </button>
    </div>
  );
};

export default AmountInput;
