'use client';

import { useGuide } from '@/app/context/GuideProvider';
import { useBridgeContext } from '@/BridgeProvider';
import { ReviewWithdrawalContent } from '@/components/ReviewWithdrawal/components/ReviewWithdrawalContent';
import { QueryKeys } from '@/constants/queryKeys';
import * as Dialog from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { useToggle } from 'react-use';
import Button from '../Button';

interface Props {
  onSubmit: () => Promise<void | null | `0x${string}` | unknown>;
  isLoading: boolean;
}

export const ReviewWithdrawal: FC<Props> = ({ onSubmit, isLoading }) => {
  const { state } = useBridgeContext();
  const [open, setOpen] = useToggle(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { showGuideById } = useGuide();

  return (
    <>
      <Dialog.Root open={open}>
        <Dialog.Trigger asChild>
          <Button
            disabled={!state.inputValue}
            onClick={setOpen}
            className="mt-6 w-full flex items-center justify-center bg-[#DFE0E1]"
          >
            Withdraw Via Mode
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-neutral-black bg-opacity-90 z-50 grid items-center justify-center overflow-y-auto p-24 animate__animated animate__fadeIn">
            <Dialog.Content className="z-50 sm:w-unset w-screen relative sm:w-fit sm:min-w-[648px] bg-[#000] border-[1px] border-neutral-500 p-8 animate__animated animate__fadeInUp animate__delay-0.3s animate__faster">
              <div className="flex items-center justify-between absolute -top-12 w-full left-0">
                <div className="text-[28px] ">Review Withdraw</div>
                <Dialog.Close className="text-white m-l-auto" onClick={setOpen}>
                  <Image src="/assets/icons/close.svg" alt="Close" width={24} height={24} />
                </Dialog.Close>
              </div>

              <ReviewWithdrawalContent
                onSubmit={async () => {
                  const res = await onSubmit();

                  if (res instanceof Error && res.name === 'TransactionExecutionError') {
                    return;
                  }

                  if (res && 'rpcUrls' in (res as object)) {
                    // this is a switch network res
                    return;
                  }

                  setTimeout(async () => {
                    queryClient.removeQueries();
                    await queryClient.invalidateQueries({ queryKey: [QueryKeys.BRIDGE_MESSAGE] });

                    router.push('/transactions');
                    showGuideById('PendingWithdrawals');
                  }, 5000);
                }}
                isLoading={isLoading}
              />
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
