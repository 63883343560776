'use client';

import { ColumnDef } from '@tanstack/react-table';
import { shortAddress } from '@/utils/format';
import { WithdrawalData } from '@/components/Withdrawals/types';
import { providers } from '@/utils/getTokenBalances';
import { getCrossChainMessenger } from '@/utils/getCrossChainMessenger';

export const PENDING_WITHDRAWALS_COLUMNS: ColumnDef<WithdrawalData>[] = [
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
  {
    accessorKey: 'transactionHash',
    header: 'Tx Hash',
    cell: ({ row }) => {
      return <div>{shortAddress(row.getValue('transactionHash'))}</div>;
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
];

export const getL1SignerCrossChainMessenger = async (l1Signer?: any) => {
  const l1provider = providers.l1;

  const l2provider = providers.l2;

  return getCrossChainMessenger(l1Signer ? l1Signer : l1provider, null, null, l2provider);
};
