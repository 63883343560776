'use client';

import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { ClaimIcon, InitiateWithdrawalIcon, ProveIcon, WaitIcon } from '@/components';

interface Props {
  active: string;
}

export const WithdrawPath: FC<Props> = () => {
  function renderRow(label: string, icon: ReactNode, _active: boolean) {
    return (
      <div
        className={clsx('flex items-center gap-4 mb-2 p-3 rounded text-neutral-500', {
          'bg-neutral-950': _active,
          'text-primary-500': _active,
        })}
      >
        {icon}
        <span className="text-white">{label}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {renderRow('Initiate Withdrawal', <InitiateWithdrawalIcon />, true)}
      {renderRow('Wait for 1 hour for proving', <WaitIcon />, false)}
      {renderRow('Prove withdrawal (on Ethereum network)', <ProveIcon />, false)}
      {renderRow('Wait for 7 days', <WaitIcon />, false)}
      {renderRow('Claim funds (on Ethereum network)', <ClaimIcon />, false)}
    </div>
  );
};
