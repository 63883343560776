'use client';

import { Button, SectionTitle } from '@/components';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { CSSProperties, ReactNode, useEffect } from 'react';
import { useToggle } from 'react-use';

export const ExternalLinkWarningModal = ({
  children,
  href,
  className,
  style,
}: {
  children: ReactNode;
  href: string;
  className?: string;
  style?: CSSProperties;
}) => {
  const { localize } = useLocalizedContentfulStrings();
  const [open, setOpen] = useToggle(false);
  const [isAllowedToNavigate, setIsAllowedToNavigate] = useToggle(false);

  useEffect(() => {
    setIsAllowedToNavigate(false);
  }, [open, setIsAllowedToNavigate]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger asChild>
        <div style={style} role="button" className={clsx(className, 'cursor-pointer')} onClick={setOpen}>
          {children}
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="text-neutral-600 fixed inset-0 bg-neutral-black bg-opacity-90 z-50 grid items-center justify-center overflow-y-auto p-4 animate-appear">
          <Dialog.Content className="max-w-[800px] flex flex-col items-center relative bg-neutral-950 p-8 rounded-2xl">
            <Dialog.Close className="text-neutral-100 absolute top-4 right-4" onClick={setOpen}>
              <Image src="/assets/icons/close.svg" alt="Close" width={24} height={24} />
            </Dialog.Close>
            <SectionTitle className="flex items-center gap-x-2 mb-6">
              <Image width={32} height={32} src="/assets/icons/alert-triangle-icon.svg" alt="Warning" />
              {localize('warningModalTitle')}
            </SectionTitle>
            <div className="flex flex-col gap-y-6">
              <p>{localize('warningModalContent')}</p>
              <div className="flex">
                <input
                  id="confirmWithdraw"
                  type="checkbox"
                  value=""
                  onChange={setIsAllowedToNavigate}
                  checked={isAllowedToNavigate}
                  className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 rounded
                focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="confirmWithdraw" className="ml-2 text-xs">
                  {localize('warningModalCheckboxLabel')}
                </label>
              </div>
              <Link
                target="_blank"
                href={href}
                className={clsx(!isAllowedToNavigate && 'pointer-events-none', 'w-full')}
                onClick={setOpen}
              >
                <Button className="w-full" disabled={!isAllowedToNavigate}>
                  {localize('continue')}
                </Button>
              </Link>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
