'use client';

import { Dispatch, createContext, useContext, useReducer } from 'react';
import localConfig from '@/config';
import { NETWORK_TOKENS } from '@/constants/tokens';
import { Token } from '@/types/token';

export type AppState = {
  view: 'deposit' | 'withdraw';
  inputValue: number | string;
  recipientValue: string;
  L1_balances: Record<string, number | string>;
  L2_balances: Record<string, number | string>;
  selectedCurrency: string;
  from: {
    network: number;
    token: Token;
  };
  to: {
    network: number;
    token: Token;
  };
};

const initialState: AppState = {
  view: 'deposit',
  inputValue: '',
  recipientValue: '',
  L1_balances: {
    ETH: 0,
    DAI: 0,
    AAVE: 0,
    LINK: 0,
  },
  L2_balances: {
    ETH: 0,
    DAI: 0,
    AAVE: 0,
    LINK: 0,
  },
  selectedCurrency: NETWORK_TOKENS[+localConfig.l1ChainId][0]?.symbol ?? 'ETH',
  from: {
    network: +localConfig.l1ChainId,
    token: NETWORK_TOKENS[+localConfig.l1ChainId][0],
  },
  to: {
    network: +localConfig.l2ChainId,
    token: NETWORK_TOKENS[+localConfig.l2ChainId][0],
  },
};

type Action =
  | { type: 'SET_VIEW'; payload: 'deposit' | 'withdraw' }
  | { type: 'SET_VALUE'; payload: number | string }
  | { type: 'RESET_INPUTS' }
  | { type: 'SET_RECIPIENT_VALUE'; payload: string }
  | {
      type: 'SET_BALANCES';
      payload: { l1: Record<string, number | string>; l2: Record<string, number | string> };
    }
  | { type: 'SET_SELECTED_CURRENCY'; payload: string }
  | { type: 'SET_SELECTED_TOKEN'; payload: Token; source: 'from' | 'to' }
  | { type: 'SET_SELECTED_NETWORK'; payload: number; source: 'from' | 'to' };

const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'SET_VIEW': {
      const { from, to } = state;

      return { ...state, view: action.payload, from: to, to: from };
    }
    case 'SET_SELECTED_TOKEN': {
      const { payload, source } = action;

      const oppositeSource = source === 'from' ? 'to' : 'from';
      const oppositeNetworkId = state.view === 'deposit' ? +localConfig.l2ChainId : +localConfig.l1ChainId;
      const oppositeToken = NETWORK_TOKENS[oppositeNetworkId].find((item) => item.symbol === payload.symbol);

      return {
        ...state,
        selectedCurrency: payload.symbol,
        [source]: {
          ...state[source],
          token: payload,
        },
        [oppositeSource]: {
          ...state[oppositeSource],
          token: oppositeToken,
        },
      };
    }
    case 'SET_VALUE':
      return { ...state, inputValue: action.payload };
    case 'SET_RECIPIENT_VALUE': {
      return { ...state, recipientValue: action.payload };
    }
    case 'RESET_INPUTS': {
      return { ...state, recipientValue: '', inputValue: '' };
    }
    case 'SET_BALANCES':
      return {
        ...state,
        L1_balances: action.payload.l1,
        L2_balances: action.payload.l2,
      };
    case 'SET_SELECTED_CURRENCY':
      return { ...state, selectedCurrency: action.payload };
    case 'SET_SELECTED_NETWORK': {
      const { payload, source } = action;

      return {
        ...state,
        [source]: {
          ...state[source],
          network: payload,
        },
      };
    }
    default:
      return state;
  }
};

const AppContext = createContext<{ state: AppState; dispatch: Dispatch<Action> } | undefined>(undefined);

interface PageProps {
  children: React.ReactNode;
}

export const BridgeProvider = ({ children }: PageProps) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useBridgeContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('error context');
  }
  return context;
};
