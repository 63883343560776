import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { OPIcon, Divider } from '@/components';
interface Props {
  amount: string;
  link?: string;
  variant?: 'primary' | 'secondary';
  className?: string;
  children?: ReactNode;
}

const bannerTheme = {
  primary: {
    layout: 'p-2 text-xs leading-4 font-medium rounded-lg text-neutral-100 bg-neutral-900',
    amount: 'text-xs font-medium leading-4 px-2 py-1',
  },
  secondary: {
    layout: 'py-2 px-8 text-sm leading-6 font-medium rounded text-neutral-100 bg-neutral-950',
    amount: 'text-base font-bold leading-4 bg-neutral-900 rounded px-2 py-1',
  },
};

export const OPBanner: FC<Props> = ({ amount = '', variant = 'primary', className, children }) => {
  const theme = bannerTheme[variant];

  return (
    <div
      className={twMerge(
        'flex flex-col md:flex-row w-full justify-between items-end md:items-center',
        theme.layout,
        className
      )}
    >
      <div className="flex flex-row">
        <div className="flex items-center">
          <OPIcon size={24} />
        </div>
        <div className="flex pl-2 items-center uppercase">{children}</div>
      </div>
      <div className="flex flex-row items-center h-full shrink-0">
        <Divider type="vertical" className="hidden md:block bg-neutral-800 shrink-0 h-6" />
        {amount && <div className={twMerge('text-[#00B2EE] shrink-0', theme.amount)}>{amount}% APY</div>}
      </div>
    </div>
  );
};
