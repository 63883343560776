import clsx from 'clsx';
import Image from 'next/image';

export const Banner = ({ text, className }: { text: string; className?: string }) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center w-full bg-sky-700 p-2 text-neutral-100 gap-x-2 text-sm font-medium',
        className
      )}
    >
      <Image width={24} height={24} src="/assets/icons/alert-triangle-icon.svg" alt={text} />
      {text}
    </div>
  );
};
