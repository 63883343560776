'use client';
import { FC } from 'react';
import AmountInput from './AmountInput';
import { useBridgeContext } from '@/BridgeProvider';

import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  estimatedGas:
    | {
        eth: number;
        usd: string;
      }
    | undefined;
  loadingGas: boolean;
}

export const InputSection: FC<Props> = ({ className = '', estimatedGas, loadingGas }) => {
  const { state } = useBridgeContext();

  const layer = state.view === 'deposit' ? 'L1_balances' : 'L2_balances';
  const isBalanceExceeded = +state[layer][state.selectedCurrency] < +state.inputValue;
  const balanceValue = state[layer]?.[state.selectedCurrency] ?? null;

  return (
    <div className={twMerge('w-full text-xs text-neutral-500', className)}>
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between uppercase">
          <p>Total amount</p>
          {balanceValue !== null && <p>Available: {balanceValue}</p>}
        </div>
        <AmountInput error={isBalanceExceeded} estimatedGas={estimatedGas} loadingGas={loadingGas} type="red" />
      </div>
      {isBalanceExceeded && (
        <div className="flex items-center mt-2">
          <div className="text-[#F12150] text-xs uppercase">Amount exceeds available balance</div>
        </div>
      )}
    </div>
  );
};

export default InputSection;
